<template>
  <v-container style="margin-left: 60px;">
    <v-row class="justify-start">
      <v-col cols="2">
        <p class="subtitle-1 d-block">Paramètres</p>
        <v-slider
          v-model="pauseThreshhold"
          :label="`Seuil de pause ${pauseThreshhold} ms`"
          min="0" max="10000" @mouseup="update()">
        </v-slider>
        <v-slider v-model="avgWin"
          :label="`Taille de fenêtre MM: ${avgWin}`"
          min="2" max="26" @mouseup="update()">
        </v-slider>
      </v-col>
      <v-col cols="2">
        <p class="subtitle-1">Calcul de la moyenne</p>
          <v-select v-model="averageSelection"
            :items="result ? result.details.map((x) => x.participant) : [1]"
            attach chips label="Participants" multiple>
          </v-select>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="justify-center">
      <v-col>
        <v-row v-if="loading">
          <v-col class="mt-5 text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <p class="body font-weight-thin">Chargement...</p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <v-row class="justify-center">
              <v-col>
                <p class="title">Résumé</p>
                  <v-simple-table dense class="text-center">
                  <template v-slot:default>
                    <thead >
                      <tr>
                        <th class="text-center">Participant</th>
                        <th class="text-center">Nombre de pauses</th>
                        <th class="text-center">Temps min (s)</th>
                        <th class="text-center">Temps max (s)</th>
                        <th class="text-center">Temps moyen (s)</th>
                        <th class="text-center">Temps médian (s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item,i in result.details" :key="i">
                        <td>{{ item.participant }}</td>
                        <td>{{ item.count }}
                          ({{ 100 * item.count / result.general[0].value | round }}%)
                        </td>
                        <td>{{ item.min / 1000 | round }}</td>
                        <td>{{ item.max / 1000| round }}</td>
                        <td>{{ item.average / 1000 | round }}</td>
                        <td>{{ item.median / 1000| round }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col class="d-flex d-block">
                <LabelledNumber
                  v-for="item,i in result.general"
                  :key="i" :value="Math.floor(item.value*100)/100"
                  :label="item.name"/>
                  <LabelledNumber
                    :value="Math.floor(selectedAverage/1000*100)/100"
                    label="Moyenne (s)"/>
                <br/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="title">Détails des silences
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Filtrer"
                  single-line hide-details ></v-text-field></p>
                <v-data-table dense :headers="headers" :items="result.silences" class="elevation-1"
                group-by="source" show-group-by multi-sort :search="search">
                  <template v-slot:item.start="{ item }">
                    <code>{{ item.start | timify }}</code>
                  </template>
                  <template v-slot:item.source="{ item }">
                      <v-chip small dark>{{ item.source}}</v-chip>
                  </template>
                  <template v-slot:item.duration="{ item }">
                    <code>{{ item.duration | timify }}</code>
                  </template>
                  <template v-slot:item.next="{ item }">
                    <v-chip small>{{ item.next}}</v-chip>
                 </template>
               </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="title">Diagrammes</p>
                <div id="chart">
                  <apexchart
                    @zoomed="selected"
                    type="line"
                    height="720"
                    :options="series.combined.options"
                    :series="series.combined.data">
                  </apexchart>
                </div>
                <div>
                  <apexchart
                    type="line"
                    height="720"
                    :options="series.speakerHistograms.options"
                    :series="series.speakerHistograms.data">
                  </apexchart>
                </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';
import LabelledNumber from '@/components/common/LabelledNumber.vue';

export default {
  name: 'Prosody',
  mixins: [MixinPatients, MixinInterviews],
  components: { LabelledNumber, apexchart: VueApexCharts },
  props: {
    patientId: String,
    interviewId: String,
  },
  computed: {
    selectedAverage() {
      if (this.loading) {
        return 0;
      }

      const elements = this.result.details
        .filter((x) => this.averageSelection.includes(x.participant))
        .map((x) => x.average);

      const sum = elements.reduce((a, b) => a + b, 0);
      const avg = (sum / elements.length) || 0;

      return avg;
    },
  },
  methods: {
    url(path) {
      return `/patient/${this.patientId}/interview/${this.interviewId}/${path}`;
    },
    async update(start = 0, end = -1) {
      this.loading = true;
      this.result = await this.getProsodyAnalysis(
        this.patientId,
        this.interviewId,
        {
          limit: this.pauseThreshhold,
          avgWin: this.avgWin,
          start,
          end,
        },
      );
      this.series.combined.data = this.result.combined;
      this.series.speakerHistograms.data = this.result.speakerHistograms;
      this.loading = false;
    },
    async selected(context, axis) {
      const start = axis.xaxis.min;
      const end = axis.xaxis.max;
      await this.update(start, end);
    },

  },

  async mounted() {
    await this.update();
  },

  data: () => ({
    result: null,
    averageSelection: [],
    zoom: { start: 0, end: -1 },
    loading: false,
    search: '',
    headers: [
      { text: 'Localisation', value: 'start' },
      { text: 'De', value: 'source' },
      { text: 'À', value: 'next' },
      { text: 'Durée', value: 'duration' },
      { text: 'Tour de parole', value: 'speechBefore' },
      { text: 'Tour suivant', value: 'speechAfter' },
    ],
    series: {
      combined: {
        data: null,
        options: {
          chart: {
            height: 720,
            type: 'line',
            stacked: false,
            animations: { enabled: false },
          },
          stroke: { width: Array(12).fill(1) },
          fill: { opacity: Array(12).fill(0.85) },
          xaxis: { title: { text: 'Tours de parole' }, tickAmount: 50 },
          yaxis: {
            title: { text: 'Durée' },
            labels: { formatter: (y) => (`${Math.floor(y / 1000)} s`) },
          },
        },
      },
      speakerHistograms: {
        data: null,
        options: {
          chart: {
            height: 720,
            type: 'line',
            stacked: false,
            animations: { enabled: false },
          },
          stroke: { width: Array(12).fill(1) },
          fill: { opacity: Array(12).fill(0.85) },
          xaxis: { title: { text: 'Durées' }, tickAmount: 50 },
          yaxis: {
            title: { text: 'Quantités de pauses' },
            labels: { formatter: (y) => (`${Math.floor(y / 1000)} s`) },
          },
          dataLabels: { enabled: false },
          title: { text: 'Distribution par participants' },
        },
      },
    },
    pauseThreshhold: 200,
    avgWin: 3,
    general: null,
  }),
};
</script>
